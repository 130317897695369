import React from 'react'
import { plantList } from '../data/plantData'
import PlantItem from './PlantItem'
import '../styles/PlantBrowser.css'

function PlantBrowser({ category, cart, updateCart }) {
    const data = plantList
    const filteredData = category === '' ? data : data.filter((plant)=>plant.category === category)

    return (
        <div className='plant-browser-container'>
            {filteredData.map((plant)=>(
                <PlantItem key={plant.id} 
                        plant={plant} 
                        cart={cart} 
                        updateCart={updateCart} 
                        displayCartButton={true}
                        displayCartController={false}/>
            ))}
        </div>
    )
}

export default PlantBrowser