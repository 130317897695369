import React from 'react'
import PlantBrowser from './PlantBrowser'
import { useState } from 'react'
import Category from './Category';
import Cart from './Cart'
import '../styles/App.css'

function App() {

  const [category, setCategory] = useState('')
  const [cart, updateCart] = useState([])

  function isCartEmpty () {
    return (cart.length === 0)
  }

  return (
    <div id='core'>
      {!isCartEmpty() && <Cart cart={cart} updateCart={updateCart}/>}
      <div>
        <Category category={category} setCategory={setCategory}/>
        <PlantBrowser category={category} cart={cart} updateCart={updateCart}/>
      </div>
    </div>
  );
}

export default App;
