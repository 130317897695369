import { plantList } from "../data/plantData"

function Category ({category, setCategory}) {

    const getAllCategories = plantList.map((plant)=>(plant.category))
    const uniqueCategories = [...new Set(getAllCategories)]

    console.log(uniqueCategories)

    function handleCategoryChange(event) {
        setCategory(event.target.value)
    }

    return (
        <div>
            <select value={category} onChange={(e)=>handleCategoryChange(e)}>
                <option value=''>Afficher toutes les catégories</option>
                {uniqueCategories.map((cat)=> <option key={cat} value={cat}>{cat}</option>)}
            </select>
        </div>
    )
}

export default Category