import '../styles/Cart.css'
import PlantItem from './PlantItem'

function Cart ({cart, updateCart}) {
    return (
        <div id="cart-panel">
            <div>Total : {cart.reduce((previousValue,currentValue)=>previousValue + (currentValue.price *currentValue.number),0)} €</div>

            {cart.map(cartItem=>(
                    <>
                        <div className='cart-item-container'>
                            <PlantItem key={cartItem.id} plant={cartItem} cart={cart} updateCart={updateCart} displayCartButton={false} displayCartNumber={true} displayCartController={true}/>
                            <div>
                                {cartItem.price * cartItem.number} €
                            </div>
                        </div>

                    </>
                    
            ))}
        </div>
    )
}

export default Cart