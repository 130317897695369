import CareScale from "./CareScale"

function PlantItem ({ plant, cart, updateCart, displayCartButton, displayCartNumber, displayCartController }) {

    function handleAddToCart () {

        const restOfTheCart = cart.filter(c=>c.name !== plant.name)

        const alreadyInCart = cart.find(c=>c.name === plant.name)
        
        var newItem = {}

        if (alreadyInCart) {
            newItem = alreadyInCart
            newItem.number +=1
        } else {
            newItem = {...plant, number: 1}
        }

        const newCart = [...restOfTheCart, newItem]
        newCart.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

        updateCart(
            newCart
        )
    }

    function handleRemoveItemFromCart () {
        updateCart(cart.filter(c=>c.name !== plant.name))
    }

    function handleMinusItem () {
        const restOfTheCart = cart.filter(c=>c.name !== plant.name)
        var item = cart.find(c=>c.name === plant.name)
        item.number -=1
        const newCart = [...restOfTheCart, item]
        newCart.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        updateCart(newCart)
    }

    return (
        <div className='plant-browser-item' style={{backgroundImage:`url(${plant.cover})`}}>
            <div className="plant-browser-item-infos">
                <div>{plant.name}</div>
                {displayCartButton &&<CareScale careType='☀️' careValue={plant.light}/>}
                {displayCartButton &&<CareScale careType='💧' careValue={plant.water}/>}
            </div>
            

            {displayCartButton && <><div className="plant-browser-item-cart" onClick={handleAddToCart}>
                Ajouter au panier
            </div></>}

            
            
            {displayCartNumber && <><div className="container-item-cart-remove" onClick={handleRemoveItemFromCart}>
                <span className="remove-item-cart">X</span>
            </div><div className="number-item-cart-controller">
                <span className="minus-item-cart" onClick={handleMinusItem}>-</span>
                <span className="number-item-cart">{plant.number}</span>
                <span className="plus-item-cart" onClick={handleAddToCart}>+</span>
            </div></>}

            

            
        </div>
    )
}

export default PlantItem